import React, { useState, useEffect } from "react";
import TagsInput from "./TagsInput";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import RegexDisplay from "./RegexDisplay";
import StreamText from "./StreamText";
import "./App.css";

const PLACEHOLDER_REGEX_TEXT =
  " your regular expression will be generated here ";

function App() {
  const [matchInput, setMatchInput] = useState([]);
  const [doMatchText, setDoMatchTextInput] = useState("");
  const [dontMatchInput, setDontMatchInput] = useState([]);
  const [dontMatchText, setDontMatchTextInput] = useState("");
  const [regexResult, setRegexResult] = useState("");
  const [explanation, setExplanation] = useState("");
  const [loading, setLoading] = useState(false);
  const isEmptyInput = matchInput.length === 0 && dontMatchInput.length === 0;

  useEffect(() => {
    if (isEmptyInput) {
      setRegexResult(PLACEHOLDER_REGEX_TEXT);
      setExplanation("");
    }
  }, [isEmptyInput]);

  useEffect(() => {
    if (loading) {
      setExplanation("");
      (async () => {
        try {
          const response = await fetch(
            process.env.NODE_ENV === "production"
              ? "https://regex-whqp5xp2aa-uc.a.run.app"
              : "http://localhost:8080/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Origin: "http://localhost:3000",
              },
              body: JSON.stringify({
                do_match: matchInput,
                dont_match: dontMatchInput,
              }),
            }
          );
          const data = await response.json();
          setRegexResult(data.regex);
          setExplanation(data.explanation);
        } catch (error) {
          console.error(error);
          setRegexResult("Error: Could not retrieve result from API.");
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [loading, dontMatchInput, matchInput]);

  const tagInProgressText = () => {
    if (doMatchText.length) {
      setMatchInput([...matchInput, doMatchText]);
      setDoMatchTextInput("");
    }
    if (dontMatchText.length) {
      setDontMatchInput([...dontMatchInput, dontMatchText]);
      setDontMatchTextInput("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    tagInProgressText();
    setLoading(true);
  };

  return (
    <div className="App">
      <RegexDisplay
        regexString={regexResult}
        isPlaceholder={regexResult === PLACEHOLDER_REGEX_TEXT}
        loading={loading}
      />
      <StreamText text={explanation} loading={loading}></StreamText>
      <div className="inputs">
        <TagsInput
          tags={matchInput}
          setTags={setMatchInput}
          placeholder="Type somethings that the regex SHOULD match"
          autoFocus
          heading={<Icon>check</Icon>}
          loading={loading}
          text={doMatchText}
          setText={setDoMatchTextInput}
        />
        <div className="submit-button">
          {!matchInput.length && !doMatchText.length ? (
            <Tooltip title="Inputs for what the regex should match required">
              <span>
                <LoadingButton
                  variant="outlined"
                  disabled
                  endIcon={<Icon>send</Icon>}
                >
                  Go
                </LoadingButton>
              </span>
            </Tooltip>
          ) : (
            <span>
              <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={handleSubmit}
                disabled={loading}
                color={"primary"}
                endIcon={<Icon>send</Icon>}
              >
                Go
              </LoadingButton>
            </span>
          )}
        </div>
        {window.screen.width > 750 &&
        <TagsInput
          tags={dontMatchInput}
          setTags={setDontMatchInput}
          placeholder="Type somethings that the regex SHOULD NOT match"
          heading={<Icon>block</Icon>}
          loading={loading}
          text={dontMatchText}
          setText={setDontMatchTextInput}
        />}
      </div>
    </div>
  );
}

export default App;
