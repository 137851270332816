import React, { useState, useEffect } from "react";

const LoadingElipsis = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      if (dots.length === 3) {
        setDots(".");
      } else {
        setDots((prevDots) => prevDots + ".");
      }
    }, 500);

    return () => clearInterval(interval);
  }, [dots]);

  return <div>{dots}</div>;
};

export default LoadingElipsis;
