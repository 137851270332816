import React, { useState, useEffect } from "react";
import "./StreamText.css";

const StreamText = ({ text, loading }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    setDisplayedText("");
  }, [text]);

  useEffect(() => {
    let interval;

    const updateText = () => {
      if (displayedText !== text) {
        setDisplayedText((prevText) => prevText + text[prevText.length]);
      } else {
        clearInterval(interval);
      }
    };

    interval = setInterval(updateText, 15);

    return () => clearInterval(interval);
  }, [displayedText, text]);

  return (
    <>
      <div className="stream-text">
        <span>{displayedText}</span>
        {(displayedText !== text || loading) && (
          <span className="cursor">&nbsp;&nbsp;</span>
        )}
      </div>
    </>
  );
};

export default StreamText;
