import React, { useState } from "react";

import "./RegexDisplay.css";
import "./LoadingElipsis";
import Icon from "@mui/material/Icon";
import LoadingElipsis from "./LoadingElipsis";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

const COPY_TEXT = "Click to copy";
const COPIED_TEXT = "✓ Copied to clipboard";

const RegexDisplay = ({ regexString, isPlaceholder, loading }) => {
  const [copyToolTipText, setCopyToolTipText] = useState(COPY_TEXT);

  // Use a regular expression to split the string into an array of substrings
  // Each substring will be either a special character or a group of characters that are not special characters
  const regex =
    /([\^\$\.\*\+\?\{\}\(\)\[\]\\\|])|([^\^\$\.\*\+\?\{\}\(\)\[\]\\\|]+)/g;
  const substrings = regexString.split(regex);

  // Map over the array of substrings and return a span element for each substring
  // Use the getClassNameForMatch function to determine the class name for each span element
  return (
    <div className="regex-highlighter-container">
      <span className="regex-highlighter">
        <span className="slash-left">/</span>
        <span
          className="regex-text"
          style={{
            opacity: isPlaceholder ? 0.55 : 1,
            fontWeight: isPlaceholder ? 300 : "bold",
            cursor: isPlaceholder || loading ? "default" : "pointer",
          }}
        >
          {loading ? (
            <LoadingElipsis />
          ) : (
            <Tooltip
              title={!isPlaceholder && copyToolTipText}
              enterDelay={0}
              enterTouchDelay={0}
              followCursor
              onClose={() => setCopyToolTipText(COPY_TEXT)}
            >
              <div
                onClick={() => {
                  if (!isPlaceholder) {
                    setCopyToolTipText(COPIED_TEXT);
                    navigator.clipboard.writeText(regexString);
                  }
                }}
                style={{
                  cursor: isPlaceholder ? "default" : "pointer",
                }}
              >
                <RegexHighlighter substrings={substrings} />
              </div>
            </Tooltip>
          )}
        </span>
        <span className="slash-right">/</span>
      </span>
      {/* pass down function to encode URL params in App, pass down to this component, just do BASE64 whatever */}

      {/* <IconButton size="small">
        <Icon size="small">share</Icon>
      </IconButton> */}
    </div>
  );
};

const RegexHighlighter = ({ substrings }) => {
  return (
    <>
      {substrings.map((substring, index) => {
        const className = getClassNameForMatch(substring);
        return (
          <span key={index} className={className}>
            {substring}
          </span>
        );
      })}
    </>
  );
};

const getClassNameForMatch = (match) => {
  switch (match) {
    case "^":
      return "regex-highlighter__caret";
    case "$":
      return "regex-highlighter__dollar";
    case ".":
      return "regex-highlighter__dot";
    case "*":
      return "regex-highlighter__asterisk";
    case "+":
      return "regex-highlighter__plus";
    case "?":
      return "regex-highlighter__question";
    case "{":
      return "regex-highlighter__curly-brace-open";
    case "}":
      return "regex-highlighter__curly-brace-close";
    case "(":
      return "regex-highlighter__paren-open";
    case ")":
      return "regex-highlighter__paren-close";
    case "[":
      return "regex-highlighter__square-brace-open";
    case "]":
      return "regex-highlighter__square-brace-close";
    case "\\":
      return "regex-highlighter__backslash";
    case "|":
      return "regex-highlighter__pipe";
    default:
      return null;
  }
};

export default RegexDisplay;
