import React, { useCallback, useRef } from "react";
import "./TagsInput.css";
function TagsInput({
  tags,
  setTags,
  placeholder,
  autoFocus,
  heading,
  loading,
  text,
  setText,
}) {
  const inputRef = useRef(null);

  const removeTag = useCallback(
    (index) => {
      setTags(tags.filter((el, i) => i !== index));
      inputRef.current.focus();
    },
    [setTags, tags]
  );
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Backspace" && text === "") {
        removeTag(tags.length - 1);
      }
      if (e.key !== "Enter" || e.shiftKey) return;
      e.preventDefault();
      const value = text;
      if (!value.trim()) return;
      setTags([...tags, value]);
      setText("");
      inputRef.current.focus();
    },
    [text, tags, setTags, setText, removeTag]
  );

  const handleFakeEnterKey = useCallback(() => {
    handleKeyDown({
      key: "Enter",
      shiftKey: false,
      preventDefault: () => {},
    });
  }, [handleKeyDown]);

  return (
    <div className="container" style={{ opacity: loading ? 0.6 : 1 }}>
      {window.screen.width > 750 && heading}
      <div className="tags-input-container">
        {tags.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        <div
          className="textarea-container"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <textarea
            onKeyDown={handleKeyDown}
            type="text"
            className="tags-input"
            placeholder={placeholder}
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={5}
            autoFocus={autoFocus}
            ref={inputRef}
          />
          <span
            className="add"
            style={{
              opacity: text.length > 0 ? 0.7 : 0.4,
              pointerEvents: text.length > 0 ? "auto" : "none",
            }}
            onClick={handleFakeEnterKey}
          >
            +
          </span>
        </div>
      </div>
      {text.length > 0 && window.screen.width > 750 ? (
        <p className="caption">
          <span>Shift + Enter to add a new line</span>
        </p>
      ) : (
        <p></p>
      )}
    </div>
  );
}

export default TagsInput;
